import React from "react"
import styled from "styled-components"
import { mediaquery } from '../variable';
import { graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import TwitterTimeLine from "../components/twitter-timeline"
import GithubContributionsGraph from "../components/github-contributions-graph"

const Sidebar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${mediaquery.desktopLg`
    margin-left: 30px;
  `}
`;

const About = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="All posts" />
      <Bio />
      <Sidebar>
        <TwitterTimeLine />
        <GithubContributionsGraph />
      </Sidebar>
    </Layout>
  )
}

export default About

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
        }
      }
    }
  }
`