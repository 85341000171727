/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
// import Image from "gatsby-image"

const Bio = () => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      avatar: file(absolutePath: { regex: "/profile-pic.jpg/" }) {
        childImageSharp {
          fixed(width: 50, height: 50, quality: 95) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      site {
        siteMetadata {
          author {
            name
            summary
          }
          social {
            twitter
          }
        }
      }
    }
  `)

  // Set these values by editing "siteMetadata" in gatsby-config.js
  const author = data.site.siteMetadata?.author
  const social = data.site.siteMetadata?.social
  const avatar = data?.avatar?.childImageSharp?.fixed

  return (
    <div className="bio">
      {/* {avatar && (
        <Image
          fixed={avatar}
          alt={author?.name || ``}
          className="bio-avatar"
          imgStyle={{
            borderRadius: `50%`,
          }}
        />
      )} */}
      {author?.name && (
        <div>
          <h4>島津 恵（しまつ めぐみ）のプロフィール</h4>
          <p>Web系企業でバックエンドエンジニアとして働いています。</p>
          <p>プログラミングが大好きです！</p>

          <h5>日々のアウトプット</h5>
          <ul>
            <li><a href="https://github.com/rubicon44">GitHub</a></li>
            <li><a href="https://qiita.com/rubicon44">Qiita</a></li>
          </ul>

          <h5>情報発信</h5>
          <ul>
            <li><a href="https://twitter.com/rubiconbc44">Twitter</a></li>
          </ul>

          <h5>過去の経歴/実績</h5>
          <p>Wantedlyにすべてまとめています。</p>
          <ul>
            <li><a href="https://www.wantedly.com/id/megumi_shimatsu">Wantedly</a></li>
          </ul>

          <h4>当ブログ（rubicon44-techblog）のコンセプト</h4>
          <p>プログラミングのアウトプットブログです。</p>
          <p>学習内容や試行錯誤した結果を体系的にまとめています。</p>
        </div>
       )}
    </div>
  )
}

export default Bio
